import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';

import { MainLayout } from '../components/Layouts';
import loginRoutes from '../features/login/routes';
import AuthRequired from 'auth/components/AuthRequired';
import PlantRequired from 'auth/components/PlantRequired';
import ExpiredLayout from 'components/Layouts/ExpiredLayout/ExpiredLayout';

// Page not found

const PageNotFoundPage = loadable(
  async () => await import('features/error-redirection/pages/PageNotFoundPage'),
);

// Dashboard

const DashboardPage = loadable(async () => await import('features/dashboard/pages/DashboardPage'));

// Gateway

const GatewaysManagementPage = loadable(
  async () => await import('features/gateways-management/pages/GatewaysManagementPage'),
);

// Sensor

const SensorsPage = loadable(async () => await import('features/sensors/pages/SensorsPage'));

// Plants Management

const PlantsManagementPage = loadable(
  async () => await import('features/plants-management/pages/PlantsManagementPage'),
);

// Firmware

const FirmwarePage = loadable(async () => await import('features/firmware/pages/FirmwarePage'));

// Platform

const PlatformPage = loadable(async () => await import('features/platform/pages/PlatformPage'));

// Parts

const PartsPage = loadable(async () => await import('features/parts/pages/PartsPage'));

// Reports
const ReportsPage = loadable(
  async () => await import('features/new-reports/pages/ReportsManagementPage'),
);

// Batteries & warranty
const BatteriesAndWArrantyPage = loadable(
  async () => await import('features/batteries&warranty/pages/Batteries&WarrantyPage'),
);

// Contracts Administration
const ContractsAdministrationPage = loadable(
  async () =>
    await import('features/contracts-administration/pages/ContractsAdministrationPage.tsx'),
);

// Link expired
const Expired = loadable(async () => await import('features/link-expired/pages/Expired'));

// Vendor
const VendorPage = loadable(async () => await import('features/vendors/pages/VendorPage.tsx'));

export default function Routes() {
  return (
    <Switch>
      <Route exact path='/login'>
        {loginRoutes}
      </Route>
      <Route
        exact
        path={[
          '/',
          '/dashboard',
          '/plants-management',
          '/gateways-management',
          '/sensors',
          '/platform',
          '/firmware',
          '/parts',
          '/reports',
          '/battery-and-warranty',
          '/contracts-administration',
          '/vendor',
        ]}
      >
        <AuthRequired>
          <PlantRequired>
            <MainLayout>
              <Route exact path='/' component={DashboardPage} key='mainPage' />
              <Route exact path='/dashboard' component={DashboardPage} key='dashboardPage' />
              <Route
                exact
                path='/gateways-management'
                component={GatewaysManagementPage}
                key='gatewaysManagementPage'
              />
              <Route
                exact
                path='/plants-management'
                component={PlantsManagementPage}
                key='plantsManagement'
              />
              <Route exact path='/platform' component={PlatformPage} key='PlatformPage' />
              <Route exact path='/sensors' component={SensorsPage} key='sensorsPage' />
              <Route exact path='/firmware' component={FirmwarePage} key='FirmwarePage' />
              <Route exact path='/parts' component={PartsPage} key='PartsPage' />
              <Route exact path='/reports' component={ReportsPage} key='ReportsPage' />
              <Route
                exact
                path='/battery-and-warranty'
                component={BatteriesAndWArrantyPage}
                key='Battery&WarrantyPage'
              />
              <Route
                exact
                path='/contracts-administration'
                component={ContractsAdministrationPage}
                key='ContractsAdministrationPage'
              />
              <Route exact path='/vendor' component={VendorPage} key='VendorPage' />
            </MainLayout>
          </PlantRequired>
        </AuthRequired>
      </Route>
      <Route exact path={'/expired'}>
        <ExpiredLayout>
          <Route exact path='/expired' component={Expired} key='Expirede' />
        </ExpiredLayout>
      </Route>
      <Route component={PageNotFoundPage} key='pageNotFoundPage' />
    </Switch>
  );
}
