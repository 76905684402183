import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';

export interface PlantsAdminSummaryState {
  summary: {
    numberOfSensors: number;
    numberOfGateways: number;
    numberOfPlants: number;
    completeDataSensors: number;
    partialDataSensors: number;
    noDataSensors: number;
    noCommissionsSensors: number;
    onlineGateways: number;
    offlineGateways: number;
    partialOnlineGateways: number;
    plantsSelected: number;
    sensorsInGWRange : number;
  };
  currentTotalRecords: number;
  search: string;
  sortBy: string;
  connectionType: string;
  plantActivity: string;
  filterPlantsBy: string;
  gwSensorStatus: string;
  sensorStatus: string;
  gwStatus: string;
}

const initialState: PlantsAdminSummaryState = {
  summary: {
    numberOfSensors: 0,
    numberOfGateways: 0,
    numberOfPlants: 0,
    completeDataSensors: 0,
    partialDataSensors: 0,
    noDataSensors: 0,
    noCommissionsSensors: 0,
    onlineGateways: 0,
    offlineGateways: 0,
    partialOnlineGateways: 0,
    plantsSelected: 0,
    sensorsInGWRange: 0,
  },
  currentTotalRecords: 0,
  search: '',
  sortBy: '',
  connectionType: '',
  plantActivity: '',
  filterPlantsBy: '',
  gwSensorStatus: '',
  sensorStatus: '',
  gwStatus: '',
};

export const plantsAdminSummarySlice = createSlice({
  name: 'plantsAdminSummary',
  initialState,
  reducers: {
    setSummary: (state, action) => {
      state.summary = action.payload;
    },
    setCurrentTotalRecords: (state, action) => {
      state.currentTotalRecords = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setConnectionType: (state, action) => {
      state.connectionType = action.payload;
    },
    setPlantActivity: (state, action) => {
      state.plantActivity = action.payload;
    },
    setFilterPlantsBy: (state, action) => {
      state.filterPlantsBy = action.payload;
    },
    setGwSensorStatus: (state, action) => {
      state.gwSensorStatus = action.payload;
    },
    setSensorStatus: (state, action) => {
      state.sensorStatus = action.payload;
    },
    setGwStatus: (state, action) => {
      state.gwStatus = action.payload;
    },
  },
});

export const {
  setSummary,
  setCurrentTotalRecords,
  setSearch,
  setSortBy,
  setConnectionType,
  setPlantActivity,
  setFilterPlantsBy,
  setGwSensorStatus,
  setSensorStatus,
  setGwStatus,
} = plantsAdminSummarySlice.actions;

export const selectSummary = (state: RootState) => state.plantsAdminSummary.summary;
export const selectCurrentTotalRecords = (state : RootState) =>
  state.plantsAdminSummary.currentTotalRecords;
export const selectSearch = (state: RootState) => state.plantsAdminSummary.search;
export const selectSortBy = (state: RootState) => state.plantsAdminSummary.sortBy;
export const selectConnectionType = (state: RootState) => state.plantsAdminSummary.connectionType;
export const selectPlantActivity = (state: RootState) => state.plantsAdminSummary.plantActivity;
export const selectFilterPlantsBy = (state: RootState) => state.plantsAdminSummary.filterPlantsBy;
export const selectGwSensorStatus = (state: RootState) => state.plantsAdminSummary.gwSensorStatus;
export const selectSensorStatus = (state: RootState) => state.plantsAdminSummary.sensorStatus;
export const selectGwStatus = (state: RootState) => state.plantsAdminSummary.gwStatus;

export default plantsAdminSummarySlice.reducer;
