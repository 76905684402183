import React, { useState, useEffect } from "react";
import { Button } from '@mui/material';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { EventType, InteractionType } from "@azure/msal-browser";
import "./Login.css"

export const IdTokenClaims = (props) => {
  return (
      <div id="token-div">
          <p><strong>Audience: </strong> {props.idTokenClaims.aud}</p>
          <p><strong>Issuer: </strong> {props.idTokenClaims.iss}</p>
          <p><strong>OID: </strong> {props.idTokenClaims.oid}</p>
          <p><strong>UPN: </strong> {props.idTokenClaims.preferred_username}</p>
      </div>
  );
}

const IdTokenContent = () => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance, 
   * an array of all accounts currently signed in and an inProgress value 
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { accounts } = useMsal();
  const [idTokenClaims, setIdTokenClaims] = useState(null);

  function GetIdTokenClaims() {
      setIdTokenClaims(accounts[0].idTokenClaims)
  }

  return (
      <>
          {/* <h5 className="card-title">Welcome {accounts[0].name}</h5>
          {idTokenClaims ?
              <IdTokenClaims idTokenClaims={idTokenClaims} />
              :
              <Button onClick={GetIdTokenClaims}> View ID Token Claims </Button>
          } */}
      </>
  );
};

const MainContent = () => {

  const { instance } = useMsal();

  /**
   * Using the event API, you can register an event callback that will do something when an event is emitted. 
   * When registering an event callback in a react component you will need to make sure you do 2 things.
   * 1) The callback is registered only once
   * 2) The callback is unregistered before the component unmounts.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/events.md
   */
  useEffect(() => {
      const callbackId = instance.addEventCallback((event) => {
          if (event.eventType === EventType.LOGIN_FAILURE) {
              if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
                  console.log(event.error);
              }
          }

          if (event.eventType === EventType.LOGIN_SUCCESS) {
              if (event?.payload) {
                  /**
                   * We need to reject id tokens that were not issued with the default sign-in policy.
                   * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
                   * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                   */
              }
          }
      });

      return () => {
          if (callbackId) {
              instance.removeEventCallback(callbackId);
          }
      };
  }, [instance]);
  instance.loginRedirect({ postLogoutRedirectUri: "/" });
  return (
      <>
        <div className="App">
            <AuthenticatedTemplate>
                <IdTokenContent />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                {/* <h5 className="card-title">Please sign-in to see your profile information.</h5> */}
                {/* <Button onClick={() => instance.loginRedirect({ postLogoutRedirectUri: "/" })}> Login </Button> */}
                {/* <Button onClick={() => instance.loginPopup({ postLogoutRedirectUri: "/", mainWindowRedirectUri: "/" })}> Login pop-up </Button> */}
            </UnauthenticatedTemplate>
        </div>
        {/* <div class="imgbox">
            <img class="center-fit" src={Img} onClick={() => instance.loginRedirect({ postLogoutRedirectUri: "/" })}/>
        </div> */}
      </>
  );
};

const Login = ({msalInstance}) => {  
  return(
    <MsalProvider instance={msalInstance}>
      <MainContent/>
    </MsalProvider>
  );
}

export default React.memo(Login);
