import React from "react";
import { Box, Grid, TextField, Autocomplete, styled } from "@mui/material";
import { LoggedInProfile } from '../LoggedInProfile';
import { useAuthQuery } from "api/queries";
import { useMsal } from "@azure/msal-react";
import style from "./Bar.module.scss";
import { setPlant } from "store/accountSlice";
import { useDispatch } from "react-redux";
import { useGetUserRoles } from "api/plants-management/users/useGetUserRoles";

const CssTextField = styled(TextField)({
    input: { 
      color: 'white',
    }, 
    '& .MuiSvgIcon-root':{
      color: 'white'
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'white',
    },
    '& .MuiStandardInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  });

const Bar = () => {
    const { data = [] } = useGetUserRoles();
    const dispatch = useDispatch();

    const userRoles = data.filter((x: any) => x.invitationStatus.toLowerCase() === 'accepted');
    React.useEffect(() => {
      if (userRoles.length) {
        dispatch(setPlant(userRoles[0].plantId));
      }
    }, [data])

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between">

            <Grid
                item
                className={style.select}>
            </Grid>
            <Grid item >
              <Box>
                <h1 style={{color:"lightgray"}}>{process.env.REACT_APP_TITLE}</h1>
              </Box>
            </Grid>
            <Grid item>
                <LoggedInProfile />
            </Grid>
        </Grid>
    )
}
export default React.memo(Bar);

//${process.env.REACT_APP_API_ENDPOINT}