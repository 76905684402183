import * as React from 'react';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Redirect } from 'react-router-dom';
import { msalInstance } from 'index';
import { PropsWithChildren } from 'react';

//can only be used in <MsalProvider>
const AuthRequired = ({ children }: PropsWithChildren<void>) => {
  return (
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Redirect to='/login' />
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
};

export default React.memo(AuthRequired);
